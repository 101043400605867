import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../helpers/PrivateRoute";
import Login from "../containers/Login/Login";
import Dashboard from "../containers/Child/Dashboard/Dashboard";
import Layout from "../components/Layout/Layout";
import ChildLayout from "../components/ChildLayout/Layout";
import GseTest from "../containers/GseTest";

const Routes = () => {
  return (
    <BrowserRouter>
      <Route path="/gse-test" component={GseTest} />
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/index" component={ChildLayout} />  
        <Route path="/" component={Layout} />
        {/* <PrivateRoute path="/index" component={Dashboard} />
        <Redirect to="/Dashboard" from="/Dashboard" /> */}
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;

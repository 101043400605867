import React from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

class GseTest extends React.Component {
  constructor(props) {
    super(props);
    console.log("GSE being connected");
  }

  sendMessage = (msg) => {
    this.clientRef.sendMessage('/topics/all', msg);
  }

  componentDidMount() {
    console.log("connecting....");
    let opts = {
      // noCredentials: true,
    }
    var sock = new SockJS('https://secondstaxgh.com:8443/spring-feed-websocket', opts);
    let stompClient = Stomp.over(sock);
    stompClient.connect({}, function(frame) {
      stompClient.subscribe('/topic/snapshot', function (notificationBody) {
        alert(JSON.parse(notificationBody.body).content);
     });

     setTimeout(() => {
      stompClient.send("/app/feed-service/snapshot.all", {}, "");
    }, 500);

    stompClient.subscribe("/user/queue/feed-service/snapshot.all", function(res) {
      console.log(res);
      });

    });
    sock.onopen = function() {
        console.log('open');
        //  sock.send('test');
    };

    sock.onmessage = function(e) {
        console.log('message', e.data);
        sock.close();
    };

    sock.onclose = function() {
        console.log('close');
    };
  }

  render() {
    return (
      <div>
        <h1>That's great</h1>
        {/* <SockJsClient url='https://secondstaxgh.com:8443/feed-service-websocket' topics={['/topic/snapshot']}
            onMessage={(msg) => { console.log(msg); }}
            ref={ (client) => { this.clientRef = client }} /> */}
      </div>
    );
  }
}

export default GseTest;


// import React from "react";

// const GseTest = () => {
//   document.body.style.background = '#fff'
//   return (
//     <>

//       <div className="container-fuild ">
//       <h1>That's great</h1>
//       </div>
//     </>
//   );
// };

// export default GseTest;